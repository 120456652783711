
import { computed, defineComponent } from "vue";
import { useSequenceRapport } from "@/graphql/sequence/sequence-closure-rapport";
import { useCloseSequence } from "@/graphql/sequence/close.sequence";
import SequenceTicketRapport from "@/components/sequence/SequenceTicketRapport.vue";

export default defineComponent({
  name: "PosRapport",
  components: { SequenceTicketRapport },
  props: ["sequence", "waitingNumber", "freeTerminal"],
  emits: ["done"],
  setup(props) {
    const { loading, result, loadRapport } = useSequenceRapport();
    loadRapport(props.sequence?.id);
    const {
      loading: loadingClose,
      sequenceRapport,
      closeSequence,
      printElement,
    } = useCloseSequence();
    return {
      loading,
      loadingClose,
      result,
      sequenceRapport,
      closeSequence,
      printElement,
      seq: computed(() => sequenceRapport.value || props.sequence),
    };
  },
});
