import { MutationCloseSequenceArgs, Sequence } from "@/graphql/types";
import { gql } from "@apollo/client/core";
import { useMutation } from "@vue/apollo-composable";
import { useI18n } from "vue-i18n";
import { useToast } from "primevue/usetoast";
import { SEQUENCE_FIELDS } from "@/graphql/sequence/sequence";
import { ref } from "vue";
import { useConfirm } from "primevue/useconfirm";
import { printElement } from "@/graphql/ticket/ticket.printer";

type CloseSequenceData = {
  closeSequence: Sequence;
};

const CLOSE_SEQUENCE = gql`
  mutation CloseSequence($id: String!) {
    closeSequence(id: $id) {
      ${SEQUENCE_FIELDS}
      terminal {
        id
        name
        description
      }
      opener {
        id
        name
      }
      closer {
        id
        name
      }
    }
  }
`;

export const useCloseSequence = () => {
  const { t } = useI18n();
  const toast = useToast();
  const confirm = useConfirm();
  const sequenceRapport = ref<Sequence>(null);
  const { loading, mutate, onDone } = useMutation<
    CloseSequenceData,
    MutationCloseSequenceArgs
  >(CLOSE_SEQUENCE, {
    update: (cache, { data }) => {
      if (data?.closeSequence?.closedAt) {
        cache.modify({
          fields: {
            activeSequence(__, { toReference }) {
              return toReference({ sequence: null, tickets: [] });
            },
          },
        });
      }
    },
  });
  onDone(({ data }) => {
    sequenceRapport.value = data?.closeSequence;
    if (data?.closeSequence) {
      setTimeout(() => {
        confirm.require({
          header: t("pos.closure0.title"),
          message: t("pos.closed"),
          group: "dialog",
          accept: () => printElement("print-rapport"),
          icon: "pi pi-info",
          acceptLabel: t("pos.print"),
          acceptClass: "p-button-warning",
          rejectClass: "locaa-hidden",
        });
      }, 250);
    } else {
      toast.add({
        severity: "warn",
        summary: t("pos.sequence"),
        detail: t(`pos.failedClose`),
        life: parseInt(process.env.VUE_APP_TOAST_LIFE),
      });
    }
  });

  function closeSequence(id: string, waiting: number) {
    if (waiting) {
      toast.add({
        severity: "warn",
        summary: t("pos.sequence"),
        detail: t("pos.waitingClose"),
        life: parseInt(process.env.VUE_APP_TOAST_LIFE),
      });
    } else {
      confirm.require({
        header: t("pos.closure0.title"),
        message: t("pos.closure0.warning"),
        group: "dialog",
        accept: () => void mutate({ id }),
        icon: "pi pi-exclamation-triangle",
        rejectLabel: t("cancel"),
        acceptLabel: t("continue"),
        acceptClass: "p-button-warning",
      });
    }
  }
  return {
    loading,
    sequenceRapport,
    closeSequence,
    printElement,
  };
};
