import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-b47730aa"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "controls-buttons p-d-inline-flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_SequenceTicketRapport = _resolveComponent("SequenceTicketRapport")!

  return (_openBlock(), _createBlock(_component_SequenceTicketRapport, {
    result: _ctx.result,
    sequence: _ctx.seq
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_Button, {
          label: _ctx.$t('pos.print'),
          class: "p-button-info",
          icon: "pi pi-print",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.printElement('print-rapport')))
        }, null, 8, ["label"]),
        (_ctx.freeTerminal)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              (!_ctx.seq?.closer)
                ? (_openBlock(), _createBlock(_component_Button, {
                    key: 0,
                    label: _ctx.$t('pos.closure0.button'),
                    class: "p-button-warning p-ml-2",
                    icon: "pi pi-times-circle",
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.closeSequence(_ctx.seq.id, _ctx.waitingNumber))),
                    loading: _ctx.loading || _ctx.loadingClose,
                    "icon-pos": "right",
                    disabled: _ctx.loading || _ctx.loadingClose
                  }, null, 8, ["label", "loading", "disabled"]))
                : (_openBlock(), _createBlock(_component_Button, {
                    key: 1,
                    label: _ctx.$t('finish'),
                    class: "p-button-warning p-ml-2",
                    icon: "pi pi-times-circle",
                    onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('done'))),
                    "icon-pos": "right"
                  }, null, 8, ["label"]))
            ], 64))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["result", "sequence"]))
}